import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Trackable } from '../../abstract/Document';
import type { ISupplier_Type } from '../Suppliers.types';
import { type Wedding, Weddings } from '../Weddings';
import type { IAddress, IContacts, ISupplier } from './Suppliers.types';
import { where } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';

@Identifiable
@Timestampable
@Trackable
export class Supplier extends AbstractDocument<ISupplier> {
  readonly collections = {};
}

export class Suppliers extends AbstractCollection<Supplier, ISupplier> {
  static definitions = {
    _: {} as ISupplier,
    address: {} as IAddress,
    contacts: {} as IContacts,
  };

  static path = 'suppliers';

  constructor(document: Wedding) {
    super(document.collection(Suppliers.path), Supplier);
  }

  static new<M extends typeof Suppliers.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<ISupplier> = {};

    if (key !== '_' && key in Suppliers.definitions) {
      result = (result[key as keyof Omit<typeof Suppliers.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<ISupplier>;
    }

    return result as M[K];
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }

  /**
   * Create supplier or update a supplier, depending on whether or not it already exists.
   */
  async add(data: ISupplier, book: boolean = false) {
    const supplier = this.getById(data.id);

    if ((await supplier.get()) == null) {
      return supplier.create(data);
    }

    return supplier.set(data, book ? ['booked'] : true);
  }

  getIgnored(type?: ISupplier_Type) {
    if (type == null) {
      return this.query([where('ignored', '==', true)]).get();
    }

    return this.query([where('type', '==', type), where('ignored', '==', true)]).get();
  }

  getShortlisted(type?: ISupplier_Type) {
    if (type == null) {
      return this.query([where('shortlisted', '==', true)]).get();
    }

    return this.query([where('type', '==', type), where('shortlisted', '==', true)]).get();
  }
}
