import type { PartialRecursive } from '../../abstract/_';
import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Timestampable, Trackable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IWeddingSubscription } from './Subscriptions.types';
import type { PartialWithFieldValue } from 'firebase/firestore';
import { mergeDeepRight } from 'ramda';

@Timestampable
@Trackable
export class Subscription extends AbstractDocument<IWeddingSubscription> {
  readonly collections = {};
}

export class Subscriptions extends AbstractCollection<Subscription, IWeddingSubscription> {
  static definitions = {
    _: {} as IWeddingSubscription,
  };

  static path = 'subscriptions';

  constructor(document: Wedding) {
    super(document.collection(Subscriptions.path), Subscription);
  }

  static new<M extends typeof Subscriptions.definitions, K extends keyof M>(key: K, value?: PartialRecursive<M[K]>) {
    let result: PartialRecursive<IWeddingSubscription> = {};

    if (key !== '_' && key in Subscriptions.definitions) {
      result = (result[key as keyof Omit<typeof Subscriptions.definitions, '_'>] as PartialRecursive<M[K]>) || {};
    }

    if (value != null) {
      result = mergeDeepRight(result, value) as PartialRecursive<M[K]>;
    }

    return result as M[K];
  }

  /**
   * Get subscription with ID 'premium'.
   */
  async getPremium() {
    return this.getById('premium').get();
  }

  /**
   * Set subscription with ID 'premium'.
   */
  async setPremium(premium: PartialWithFieldValue<IWeddingSubscription>) {
    return this.getById('premium').update(premium);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
