import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Trackable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IVisitor } from './Visitors.types';

@Identifiable
@Timestampable
@Trackable
export class Visitor extends AbstractDocument<IVisitor> {
  readonly collections = {};
}

/**
 * @deprecated This model is still preliminary, do not use.
 */
export class Visitors extends AbstractCollection<Visitor, IVisitor> {
  static definitions = {
    _: {} as IVisitor,
  };

  static path = 'visitors';

  constructor(document: Wedding) {
    super(document.collection(Visitors.path), Visitor);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
