import { AbstractCollection } from '../../abstract/Collection';
import { AbstractDocument, Identifiable, Timestampable, Trackable } from '../../abstract/Document';
import { type Wedding, Weddings } from '../Weddings';
import type { IKeyword } from './Keywords.types';

@Identifiable
@Timestampable
@Trackable
export class Keyword extends AbstractDocument<IKeyword> {
  readonly collections = {};
}

export class Keywords extends AbstractCollection<Keyword, IKeyword> {
  static definitions = {
    _: {} as IKeyword,
  };

  static path = 'keywords';

  constructor(document: Wedding) {
    super(document.collection(Keywords.path), Keyword);
  }

  get wedding() {
    return Weddings._.getById(this.reference.parent.id);
  }
}
